
.slider {
  position: relative;

  &__container {
    display: flex;
    padding: 0 55px;
    transition: transform 300ms ease 100ms;
    z-index: 3;
    width: 100%;
  }

  &:not(&--open) .item:hover .show-details-button {
    opacity: 1;
  }

  &:not(&--open) {
    .item:hover {
      transform: scale(1.2);
      z-index: 2;
      transition-delay: .4s;
    }

    @media (min-width: 1400px) {
      .item-4:hover,
      .item-9:hover,
      .item-13:hover,
      .item-17:hover,
      .item-25:hover {
        transform: scale(1.2) translateX(-50px) !important;
      }

      .item-0:hover,
      .item-5:hover,
      .item-10:hover,
      .item-15:hover,
      .item-20:hover,
      .item-25:hover {
        transform: scale(1.2) translateX(55px) !important;
      }
    }
  }
}
