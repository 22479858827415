.banner-logged{
  width: 100vw !important;
  margin-top: 61.5px;
  height: 204px;
  transition: all .4s;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .banner-logged{
    margin-top: 62.125px;
    height: 160px !important;
  }
}
@media (max-width: 480px) {
  .banner-logged{
    margin-top: 62.75px;
    height: 110px !important;
  }
}
@media (max-width: 425px) {
  .banner-logged{
    margin-top: 48.75px;
    height: 110px !important;
  }
}