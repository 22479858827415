:root {
  --blue-primary: #222C69;
  --blue-secondary: #435384;
  --orange-primary: #F05024;
  --orange-secondary: #F7A28E;
  --gray-primary: #808080;
  --gray-secondary: #BDBEBE;
  --navy: #021222;
  --gray-bg: #e3e3ee;

  /* FIGMA STYLES PROFILE PAGE */

    /* Colors */

  --clr-primary-default: #222B6A;
  --clr-primary-dark: #00013F;
  --clr-primary-light: #525399;
  --clr-primary-bg: #D8DCF3;

  --clr-secondary-default: #CBAF63;
  --clr-secondary-dark: #988036;
  --clr-secondary-light: #FFE192;

  --clr-neutral-100: #2D2935;
  --clr-neutral-200: #3A3847;
  --clr-neutral-300: #605E70;
  --clr-neutral-400: #737185;
  --clr-neutral-500: #888599;
  --clr-neutral-600: #9D9AAD;
  --clr-neutral-700: #C5C0DB;
  --clr-neutral-800: #F4F3FE;
  --clr-neutral-900: #FFFFFF;

  --clr-feedback-negative: #F13C46;
  --clr-feedback-warning: #FF9C2B;
  --clr-feedback-informational: #0D69D1;
  --clr-feedback-successful: #00C54A;
  --clr-feedback-disabled: #D3D3D3;

  /* Font Families */
  --font-family-headline: 'Merriweather Sans', sans-serif;
  --font-family-body: 'Work Sans', sans-serif;

  /* Font sizes */
  --fs-headline-display: 56px;
  --fs-headline-title-large: 48px;
  --fs-headline-title-medium: 40px;
  --fs-headline-subtitle-large: 25px;
  --fs-headline-subtitle-medium: 20px;
  --fs-body-text-large: 18px;
  --fs-body-text-medium: 16px;
  --fs-body-subtext-large: 13px;
  --fs-body-subtext-medium: 10px;

  /* Line Heights */
  --lh-tight: 100%;
  --lh-medium: 110%;
  --lh-spaced: 120%;
  --lh-distant: 130%;

  /* Font weights */
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;

  /* Text Decorations */
  --txt-underline: underline;

  /* Gradients and Elevations */
  --locked-certificate: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5) 0%, rgba(8, 8, 8, 0.5) 20.31%, rgba(40, 40, 40, 0.38) 100%);

  --elevation-1: 0px 1px 2px rgba(142, 141, 208, 0.3), 0px 1px 3px 1px rgba(142, 141, 208, 0.15);
  --elevation-2: 0px 1px 2px rgba(142, 141, 208, 0.3), 0px 2px 6px 2px rgba(142, 141, 208, 0.15);
  --elevation-3: 0px 4px 8px 3px rgba(142, 141, 208, 0.15), 0px 1px 3px rgba(142, 141, 208, 0.3);
  --elevation-4: 0px 8px 16px rgba(142, 141, 208, 0.12);
  --elevation-5: 0px 6px 10px 4px rgba(142, 141, 208, 0.15), 0px 2px 3px rgba(142, 141, 208, 0.3);
  --elevation-6: 0px 8px 12px 6px rgba(140, 140, 160, 0.836), 0px 4px 4px rgba(140, 140, 160, 0.555);
}

body {
  background-color: #FDFDFF !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--clr-primary-light); 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--clr-primary-default); 
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-body) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--navy);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.video-js {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  /* height: 100vh !important; */
  background-color: var(--navy) !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
}

.video-js .vjs-big-play-button {
  background-color: #FFF !important;
  border-radius: 8px !important;
  position: sticky !important;
  border: 3px solid var(--orange-primary) !important;
  font-size: 32px!important;
}

.vjs-loading-spinner {
  border: 6px solid var(--orange-primary) !important;
} 

.vjs-loading-spinner:before, .vjs-loading-spinner:after {
  border-top-color: var(--orange-secondary) !important;
}

.video-js .vjs-play-progress {
  background-color: #f05024 !important;
}

.vjs-icon-circle:before, .vjs-seek-to-live-control .vjs-icon-placeholder:before, .video-js .vjs-volume-level:before, .video-js .vjs-play-progress:before {
  color: var(--orange-primary) !important;
  font-size: 24px !important;
}

.video-js .vjs-play-progress:before {
  top: -0.4em !important;
}

.video-js .vjs-load-progress {
  background: var(--orange-secondary) !important;
}

.vjs-button > .vjs-icon-placeholder {
  color: var(--orange-primary) !important;
}

.video-js .vjs-control-bar {
  background-color: #021222d2 !important;
}

.vjs-menu li {
  background: var(--navy) !important;
  color: #FFF !important;
}

.vjs-menu li.vjs-selected {
  background-color: var(--orange-primary) !important;
  color: #FFF !important;
}

.vjs-slider-horizontal .vjs-volume-level:before {
  top: -0.4em !important;
  right: -0.5em;
}

.vjs-icon-play:before, .video-js .vjs-play-control .vjs-icon-placeholder:before, .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  color: var(--orange-primary) !important;
}

.vjs_video_3-dimensions.vjs-fluid {
  padding-top: 0;
}

.hover-zoom{
  transition: all 0.3s linear;
}
.hover-zoom:hover{
  transform: scale(1.2)
}