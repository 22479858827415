.progress-circle {
    position: relative;
}

.videos-container {
    width: 1600px;
}

.videos-in-category {
    width: 100%;
}

.videos-container-subcategory {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;  
    
    @media (max-width: 1500px) {   
    grid-template-columns: repeat(3, 1fr);        
    }
    @media (max-width: 1250px) {
    width: 80%;   
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);        
    }
    @media (max-width: 900px) {
    width: 60%;   
    grid-template-columns: repeat(1, 1fr);        
    }
    @media (max-width: 390px) {
    width: 60%;   
    grid-template-columns: repeat(1, 1fr); 
    height: 56px;       
    }

}
